<template>
  <ReportsDownloadFilePage
    v-if="reportId"
    :key="`report-page-lithium-${marketSlug}`"
    :report-category="reportCategory"
    :report-id="reportId"
  />
</template>

<script setup lang="ts">
import ReportsDownloadFilePage from '~/components/reports/DownloadFilePage.vue'
import {
  ALL_MARKETS,
  CATHODE,
  ESG,
  FORECASTS,
  GIGAFACTORY_ASSESSMENT,
  LITHIUM_ION_BATTERIES,
  MARKET,
  MARKET_ASSESSMENTS,
  PRICE_ASSESSMENTS,
  SUBSCRIPTION_MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT,
} from '~/constants/services/constants'

definePageMeta({ layout: 'overviews', middleware: ['has-active-membership'] })

const route = useRoute()

const marketsMap = ALL_MARKETS.reduce(
  (acc, market) => ({ ...acc, [market.slug]: market }),
  {} as Record<string, (typeof ALL_MARKETS)[number]>,
)
const reportId: string = route.params.id as string
const subCategory = route.params.subCategory as string
const marketSlug = route.params.market as string
const market = marketsMap[marketSlug]

const reportCategory = computed(() => {
  if (!market) return null

  if (
    (marketSlug === CATHODE.slug && subCategory === MARKET_ASSESSMENTS.slug) ||
    (marketSlug === LITHIUM_ION_BATTERIES.slug &&
      subCategory === PRICE_ASSESSMENTS.slug)
  ) {
    return SUBSCRIPTION_MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT
  }

  if (
    marketSlug === LITHIUM_ION_BATTERIES.slug &&
    subCategory === MARKET_ASSESSMENTS.slug
  ) {
    return GIGAFACTORY_ASSESSMENT.categoryReportPricing
  }

  switch (subCategory) {
    case ESG.singularSlug:
      return market?.categoryReportEsg
    case FORECASTS.singularSlug:
      return market?.categoryReportForecast
    case MARKET_ASSESSMENTS.slug:
    case PRICE_ASSESSMENTS.slug:
      return market?.categoryReportPricing
    default:
      return null
  }
})

const { getSeoInfo } = useSeo()
const seoInfo = getSeoInfo({
  title: `${MARKET.name}: ${market?.name || ''} Report`,
  description: ALL_MARKETS[marketSlug]?.seo.priceAssessments.reportDescription,
})

useHead(seoInfo)
</script>
